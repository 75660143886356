import * as React from "react";
import "../fonts/fonts.css";
import "./styles.scss";
import Header from "../components/header";
import Footer from "../components/footer";
import DATA from "../data";
import instagram from "../images/instagram.svg";
import behance from "../images/behance.svg";
import linkedin from "../images/linkedin.svg";
import artstation from "../images/artstation.svg";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => {
  return (
    <main id="about">
      <title>{DATA.title} - About</title>
      <Header withNavigation />
      <div className="content">
        <div className="inner">
          <div className="grid">
            <div className="pane description">
              <h2>
                My name is Bartosz Palus
                <br />
                and I love good design
              </h2>
              <p className="about-me">
                3d graphics helps me express myself, show my world and my own
                projects on the border of art, architecture and design.
                <br />I was inspired by a number of a different trends during my
                3d career which has then translated into projects I carried out.
              </p>
              <div className="social">
                <a target="_blank" href={DATA.social.behance}>
                  <img src={behance} alt="Bartosz Palus on Behance" />
                </a>
                <a target="_blank" href={DATA.social.instagram}>
                  <img src={instagram} alt="Bartosz Palus on Instagram" />
                </a>
                <a target="_blank" href={DATA.social.linkedin}>
                  <img src={linkedin} alt="Bartosz Palus on Linkedin" />
                </a>
                <a target="_blank" href={DATA.social.artstation}>
                  <img src={artstation} alt="Bartosz Palus on ArtStation" />
                </a>
              </div>
            </div>
            <div className="pane">
              <StaticImage
                src="../images/portrait.png"
                alt="Bartosz Palus"
                className="portraitStyles"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default IndexPage;
